<template>
    <Dialog 
        :visible="isOpen"
        class="p-fluid" 
        :style="{ width: '450px' }" 
        header="Remover rascunho" 
        :draggable="false" 
        :closable="false" 
        :modal="true"
    >
        Deseja excluir o rascunho?
        <template #footer>
            <Button
                label="Não"
                icon="pi pi-times"
                class="p-button-text"
                @click="onCloseDialog"
            />
            <Button
                :loading="loadingConfirm"
                label="Sim"
                icon="pi pi-check"
                autofocus
                @click="onConfirmDialog"
            />
        </template>
    </Dialog>
</template>

<script>
import InventarioRiscoService from '../services/InventarioRiscoService';

export default {
    emits: ['onAfterConfirmRemoverRascunho'],
    data() {
        return {
            rowData: null,
            isOpen: false,
            serviceInventarioRisco: null,
            loadingConfirm: false
        }
    },
    created() {
        this.serviceInventarioRisco = new InventarioRiscoService();
    },
    methods: {
        open(record) {
            this.rowData = record;
            this.isOpen = true;
        },
        async onConfirmDialog() {
            try {
                this.loadingConfirm = true;
                const { inventarioRiscoId } = this.rowData;
                await this.serviceInventarioRisco.remove(inventarioRiscoId);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Rascunho removido com sucesso!',
                    life: 3000
                });
                this.loadingConfirm = false;
                this.onCloseDialog();
                this.$emit('onAfterConfirmRemoverRascunho', this.rowData);
            } catch (error) {
                this.loadingConfirm = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao remover rascunho!',
                    detail: error,
                    life: 3000
                });
            }
        },
        onCloseDialog() {
            this.isOpen = false;
        }
    }
}
</script>

<style>

</style>