<template>
    <div class="card">
        <div class="text-800 mb-1" v-if="tooltip || subtitle">
            <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                  <AppInfoManual nomeTelaDoManual="inventario-risco-panel-form" />
                  <h3 class="ml-1">{{ subtitle }}</h3>
             </div>
        </div>
        <slot name="filtros"></slot>
        <slot name="content"></slot>
    </div>
</template>

<script>
import AppInfoManual from '../../../components/AppInfoManual.vue';

export default {
    props: {
        subtitle: {
            type: String
        },
        tooltip: {
            type: String
        },
    },
    components: { AppInfoManual }
};
</script>