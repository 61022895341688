<template>
    <PaginatedGrid ref="grid" :service="service" :filtrosExtras="filtrosExtras" :mapFilters="mapFilters">
        <template #columns>
            <Column header="Status">
                <template #body="{ data }">
                    <StatusAplicacao
                        :status="{
                            levantamento: data?.aplicacaoLevantamento?.status,
                            aepAet: data?.aplicacaoAep?.status ?? data?.aplicacaoAet?.status,
                            pgr: data?.aplicacaoPgr?.status,
                            laudo: data?.aplicacaoLaudo?.status,
                            pcmso: data?.aplicacaoPcmso?.status
                        }"
                    />
                </template>
            </Column>
            <Column field="inventarioRisco.perfilExposicao.nome" header="Perfil / Agrupamento">
                <template #body="slotProps">
                    <div class="truncate-text">
                        {{
                            slotProps?.data?.inventarioRisco?.perfilExposicaoId
                                ? slotProps?.data?.inventarioRisco?.perfilExposicao?.nome
                                : slotProps?.data?.inventarioRisco?.identificacaoAgrupamento
                        }}
                    </div>
                </template>
            </Column>
            <Column field="hierarquia.cliente.grupo.descricao" header="Grupo" />
            <Column field="hierarquia.cliente.name" header="Cliente" />
            <Column field="hierarquia.customerBranch.name" header="Unidade" />
            <Column headerStyle="width:40px;">
                <template #body="{ data }">
                    <div v-tooltip.bottom="'Levantamento em andamento'">
                        <div v-if="data?.origemAplicacao === enumOrigemAplicacaoInventarioHierarquia.CHECKLIST">
                            <Button style="background-color: #fff; border-color: #fff">
                                <iphoneSmartphone
                                    style="
                                         {
                                            width: 25px;
                                            height: 22px;
                                        }
                                    "
                                    :status="data?.aplicacaoLevantamento?.status"
                                    @click="openDialogInformacoesExtras(data)"
                                />
                            </Button>
                        </div>
                    </div>
                    <div v-tooltip.bottom="'Levantamento concluído'">
                        <div v-if="data?.origemAplicacao === enumOrigemAplicacaoInventarioHierarquia.CHECKLIST_INVENTARIO">
                            <Button style="background-color: #fff; border-color: #fff">
                                <iphoneSmartphone
                                    style="
                                         {
                                            width: 25px;
                                            height: 22px;
                                        }
                                    "
                                    :status="data?.aplicacaoLevantamento?.status"
                                    @click="openDialogInformacoesExtras(data)"
                                />
                            </Button>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="hierarquia.customerDepartment.name" header="Setor" />
            <Column field="hierarquia.customerPosition.name" header="Cargo" />
            <Column>
                <template #body="slotProps">
                    <Button
                        v-if="$checkPermission('smt_ir:editar')"
                        icon="pi pi-pencil"
                        class="p-button-sm p-button-outlined"
                        v-tooltip.bottom="'Editar'"
                        @click="editar(slotProps)"
                    />
                    <Button
                        v-if="$checkPermission('smt_ir:excluir')"
                        icon="pi pi-trash"
                        class="p-button-sm p-button-outlined ml-2"
                        @click="openDialog(slotProps)"
                    />
                </template>
            </Column>
        </template>
        <template v-slot:expansion="slotProps">
            <h5 class="ml-5">{{ `Funcionários: ${totalFuncionarios}` }}</h5>
            <GridFuncionarios
                class="ml-5"
                :service="serviceFuncionario"
                :filtrosExtras="{
                    idHierarquia: slotProps.data.hierarquiaId,
                    situacoes: [
                        enumSituacaoFuncionario.PENDENTE,
                        enumSituacaoFuncionario.ATIVO,
                        enumSituacaoFuncionario.FERIAS,
                        enumSituacaoFuncionario.AFASTADO
                    ]
                }"
                @onChangeTotalItems="onChangeTotalItemsFuncionario"
            >
                <template #columns>
                    <Column field="name" header="Nome" />
                    <Column field="situation" header="Situação" />
                </template>
            </GridFuncionarios>
        </template>
    </PaginatedGrid>
    <DialogExcluirEmAndamento ref="dialogExcluirEmAndamento" :isOpen="isOpen" @onloadEmAndamento="onloadEmAndamento" @onCloseDialog="onCloseDialog" />
    <Dialog
        :visible="isOpenDialogInformacoesExtras"
        class="p-fluid"
        header="Informações sobre o levantamento"
        :modal="true"
        :style="{ width: '600px' }"
    >
        <div class="flex" style="font-size: 16px">
            <p class="mr-3 font-bold">Responsavel:</p>
            <p>{{ responsavel }}</p>
        </div>
        <div class="flex" style="font-size: 16px">
            <p class="mr-3 font-bold">Data Liberação:</p>
            <p>{{ $filters.formatDateOnly(dataLiberacao) }}</p>
        </div>
        <div class="flex" style="font-size: 16px">
            <p class="mr-3 font-bold">Data Conclusão:</p>
            <p>{{ dataConclusao ? $filters.formatDateOnly(dataConclusao) : 'Em levantamento' }}</p>
        </div>

        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="onCloseDialogInformacoesExtras" />
        </template>
    </Dialog>
</template>

<script>
import BaseService from '../../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import PaginatedGrid from './PaginatedGrid.vue';
import StatusAplicacaoInventario from '../../../enums/StatusAplicacaoInventario';
import GridFuncionarios from './GridFuncionarios.vue';
import StatusAplicacao from './StatusAplicacao.vue';
import DialogExcluirEmAndamento from './DialogExcluirEmAndamento.vue';
import OrigemAplicacaoInventarioHierarquiaEnum from '../../../enums/OrigemAplicacaoInventarioHierarquiasEnum';
import StatusAplicacaoLevantamentoEnum from '../../../enums/StatusAplicacaoLevantamentoEnum';
import iphoneSmartphone from '../../../../public/layout/images/dashboard/iphoneSmartphone.vue';
import { showError } from '../../../utils/Toast';
import SituationEnum from '../../../enums/SituationEnum';

export default {
    components: {
        PaginatedGrid,
        GridFuncionarios,
        StatusAplicacao,
        iphoneSmartphone,
        DialogExcluirEmAndamento
    },
    props: {
        filtrosExtras: {
            type: Object
        }
    },
    data() {
        return {
            responsavel: null,
            dataLiberacao: null,
            dataConclusao: null,
            service: null,
            isOpen: false,
            serviceFuncionario: null,
            enumSituacaoFuncionario: SituationEnum,
            enumStatusAplicacaoInventario: StatusAplicacaoInventario,
            enumOrigemAplicacaoInventarioHierarquia: OrigemAplicacaoInventarioHierarquiaEnum,
            totalFuncionarios: 0,
            isOpenDialogInformacoesExtras: false,
            mapFilters: [
                {
                    filter: 'cliente',
                    value: 'cliente.name'
                },
                {
                    filter: 'unidade',
                    value: 'customerBranch.name'
                },
                {
                    filter: 'setor',
                    value: 'customerDepartment.name'
                },
                {
                    filter: 'cargo',
                    value: 'customerPosition.name'
                },
                {
                    filter: 'funcionario',
                    value: 'customerEmployee.name'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/inventario-risco/em-andamento');
        this.serviceFuncionario = new BaseService('/customer/employees');
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    methods: {
        async onloadEmAndamento() {
            await this.load();
        },
        async openDialog(slotProps) {
            this.isOpen = true;
            await this.$refs.dialogExcluirEmAndamento.load(slotProps.data.inventarioRiscoId);
        },
        onCloseDialog() {
            this.isOpen = false;
        },
        async load() {
            await this.$refs?.grid?.load();
        },
        onChangeTotalItemsFuncionario(totalFuncionarios) {
            this.totalFuncionarios = totalFuncionarios;
        },
        editar(slotProps) {
            const { inventarioRisco } = slotProps.data;
            const { origemAplicacao, aplicacaoLevantamento } = slotProps.data;
            const responsaveis = slotProps.data.inventarioRisco.inventarioRiscoResponsavel.map((item) => item.profissionalSesmt.nome);
            if (
                origemAplicacao == OrigemAplicacaoInventarioHierarquiaEnum.CHECKLIST &&
                aplicacaoLevantamento.status == StatusAplicacaoLevantamentoEnum.EM_ANDAMENTO
            ) {
                const error = {
                    message: `Não é possível editar, pois existe um processo de levantamento em andamento pelo checklist digital com o(s) profissional(is): ${responsaveis}`
                };
                showError(this.$toast, error);
                return;
            }
            this.$router.push(`/security/inventario-risco2/aplicacao/${inventarioRisco.id}/${inventarioRisco.etapa}`);
        },
        openDialogInformacoesExtras(slotProps) {
            this.dataLiberacao = slotProps?.aplicacaoLevantamento?.dataLiberacao;
            this.dataConclusao = slotProps?.aplicacaoLevantamento?.dataConclusao;
            let nomeResponsavel = [];
            slotProps?.inventarioRisco?.inventarioRiscoResponsavel.forEach((element) => (nomeResponsavel = element?.profissionalSesmt?.nome));
            this.responsavel = nomeResponsavel;
            this.isOpenDialogInformacoesExtras = true;
        },
        onCloseDialogInformacoesExtras() {
            this.isOpenDialogInformacoesExtras = false;
        }
    }
};
</script>

<style>
.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Defina a largura máxima da célula conforme necessário */
}

.truncate-text:hover {
    white-space: normal;
    overflow: visible;
    max-width: none;
}
</style>
