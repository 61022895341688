<template>
    <Dialog 
        :visible="isOpen" 
        class="p-fluid" 
        :style="{ width: '450px' }" 
        header="Filtros" 
        :draggable="false" 
        :closable="false" 
        :modal="true"
    >
        <div class="field">
            <label for="customerDepartmentId">Setor</label>
            <MultiSelectSetor
                v-model="filters.setoresSelecionados"
                dataKey="id"
                optionLabel="name" 
                placeholder="Selecione..."
            />
        </div>
        <div class="field">
            <label for="customerPositionId">Cargo</label>
            <MultiSelectCargo
                v-model="filters.cargosSelecionados"
                dataKey="id"
                optionLabel="name" 
                placeholder="Selecione..."
            />
        </div>
        <div class="field-checkbox mt-5">
            <Checkbox inputId="hierarquiaAtiva" v-model="filters.hierarquiaAtiva" :binary="true" />
            <label for="hierarquiaAtiva">Hierarquia ativa</label>
        </div>
        <div class="field-checkbox mt-1">
            <Checkbox inputId="hierarquiaInativa" v-model="filters.hierarquiaInativa" :binary="true" />
            <label for="hierarquiaInativa">Hierarquia inativa</label>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClickClose" />
            <Button label="Aplicar" icon="pi pi-check" class="p-button-outlined p-button-success" @click="emitChange()" />
        </template>
    </Dialog>
</template>

<script>
import MultiSelectSetor from '../../setores/components/MultiSelectSetor.vue';
import MultiSelectCargo from '../../cargos/components/MultiSelectCargo.vue';
export default {
    emits: ['change'],
    components: {
        MultiSelectSetor,
        MultiSelectCargo
    },
    data() {
        return {
            loadingOptions: false,
            isOpen: false,
            filters: {
                hierarquiaAtiva: true
            }
        };
    },
    methods: {
        onClickClose() {
            this.isOpen = false;
        },
        open() {
            this.isOpen = true;
        },
        emitChange() {
            this.$emit('change', this.filters);
            this.onClickClose();
        }
    }
};
</script>