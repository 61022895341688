<template>
    <div class="mt-3 mb-3">
        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between">
            <div class="field w-4">
                <label for="customersId">Cliente</label>
                <MultiSelectCliente 
                    id="customersId"
                    v-model="filters.clientesSelecionados"
                    dataKey="id"
                    optionLabel="name" 
                    placeholder="Selecione..."
                />
            </div>
            <div class="field w-4 ml-3">
                <label for="customerBranchesId">Unidade</label>
                <MultiSelectUnidade 
                    id="customerBranchesId"
                    v-model="filters.unidadesSelecionadas"
                    dataKey="id"
                    optionLabel="name" 
                    placeholder="Selecione..."
                />
            </div>
        </div>
        
        <div class="flex flex-row p-fluid mt-2 md:mt-0 justify-items-between">
            <Dropdown
                id="pesquisarPor"
                v-model="filters.pesquisarPor"
                :options="optionsPesquisarPor"
                optionLabel="label"
                optionValue="value"
                placeholder="Pesquisar por..."
                class="mr-3 w-3"
            />
            <Chips v-model="filters.text" placeholder="Pesquisar" class="w-full" />
            <Button v-if="false" label="Filtros" icon="pi pi-plus" class="p-button p-button-text w-2 ml-2" @click="openDialogMaisFiltros" />
            <Button label="Aplicar filtros" icon="pi pi-search" class="p-button p-button-success w-4 ml-2" @click="aplicarFiltros" />
        </div>
        <DialogMaisFiltrosInventarioRisco ref="dialogMaisFiltros" @change="onApplyDialogMaisFiltros" />
    </div>
</template>

<script>
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente.vue';
import MultiSelectUnidade from '../../unidades/components/MultiSelectUnidade.vue';
import DialogMaisFiltrosInventarioRisco from './DialogMaisFiltrosInventarioRisco.vue';
export default {
    emits: ['onChangeFiltro'],
    components: {
        MultiSelectCliente,
        MultiSelectUnidade,
        DialogMaisFiltrosInventarioRisco
    },
    watch: {
        'filters.clientesSelecionados'() {
            this.createFiltersWhere();
        },
        'filters.unidadesSelecionadas'() {
            this.createFiltersWhere();
        },
        'filters.pesquisarPor'() {
            this.createFiltersWhere();
        },
        'filters.text'() {
            this.createFiltersWhere();
            this.$emit('onChangeFiltro', this.filtrosExtras);
        },
        'maisFiltros'() {
            this.createFiltersWhere();
            this.$emit('onChangeFiltro', this.filtrosExtras);
        }
    },
    data() {
        return {
            filtrosExtras: {},
            filters: {
                pesquisarPor: 'unidade'
            },
            maisFiltros: {},
            optionsPesquisarPor: [
                { label: 'Cliente', value: 'cliente' },
                { label: 'Unidade', value: 'unidade' },
                { label: 'Setor', value: 'setor' },
                { label: 'Cargo', value: 'cargo' },
                { label: 'Funcionário', value: 'funcionario' }
            ]
        };
    },
    methods: {
        createFiltersWhere() {
            const where = [
                {
                    prop: this.filters?.pesquisarPor,
                    operator: 'contains',
                    values: this.filters?.text,
                    ilikeAny: this.filters?.pesquisarPor !== 'funcionario' ? true : false
                }
            ];

            if (this.filters?.clientesSelecionados?.length) {
                where.push({
                    prop: 'cliente.id',
                    operator: 'in',
                    values: this.filters.clientesSelecionados.map(p => p.id)
                })
            }

            if (this.filters?.unidadesSelecionadas?.length) {
                where.push({
                    prop: 'customerBranch.id',
                    operator: 'in',
                    values: this.filters.unidadesSelecionadas.map(p => p.id)
                })
            }

            if (this.maisFiltros?.setoresSelecionados?.length) {
                where.push({
                    prop: 'customerDepartment.id',
                    operator: 'in',
                    values: this.maisFiltros.setoresSelecionados.map(p => p.id)
                })
            }

            if (this.maisFiltros?.cargosSelecionados?.length) {
                where.push({
                    prop: 'customerDepartment.id',
                    operator: 'in',
                    values: this.maisFiltros.cargosSelecionados.map(p => p.id)
                })
            }

            this.filtrosExtras = { 
                where 
            };
        },
        openDialogMaisFiltros() {
            this.$refs.dialogMaisFiltros.open();
        },
        onApplyDialogMaisFiltros(filters) {
            this.emitChange(filters);
        },
        aplicarFiltros() {
            this.$emit('onChangeFiltro', this.filtrosExtras);
        },
        emitChange(filters) {
            this.maisFiltros = filters;
            this.createFiltersWhere();
        }
    }
};
</script>
