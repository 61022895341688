<template>
    <AppMultiselect
        v-bind="$attrs"
        v-model="value"
        :service="serviceSetor"
        optionLabel="name"
    />
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        }
    },    
    emits: ['update:modelValue'],
    data() {
        return {
            serviceSetor: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.serviceSetor = new BaseService('/customer/departments')
    }
};
</script>
