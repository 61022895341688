<template>
    <div class="flex flex-row gap-3">
        <div class="" v-for="produtoServico in produtosServicos" :key="produtoServico">
            <div >
                <i class="font-semibold text-gray-600" 
                    :class="getIcon(produtoServico)"
                    v-tooltip.bottom="getProdutoServicoLabel(produtoServico)"
                ></i>
            </div>
        </div>
    </div>
</template>

<script>
import TipoInventarioEnum, { TipoInventarioIconEnum, TipoInventarioIconOrderEnum } from '../../../enums/TipoInventarioEnum';

export default {
    props: {
        records: {
            type: Array,
            require: true
        }
    },
    data() {
        return {
            produtosServicos: []
        };
    },
    mounted() {
        this.getProdutosServicosContratados(this.records);
    },
    methods: {
        getProdutosServicosContratados(produtosServicosCliente) {
            const produtosServicos = produtosServicosCliente?.map((p) => p?.produtoServico?.tipoInventario);
            const produtosServicosOrdenados = produtosServicos.sort(TipoInventarioIconOrderEnum);
            this.produtosServicos = produtosServicosOrdenados;
        },
        getIcon(produtoServico) {
            return TipoInventarioIconEnum.get(produtoServico);
        },
        getProdutoServicoLabel(produtoServico) {
            return TipoInventarioEnum[produtoServico];
        }
    }
};
</script>