<template>
    <div>
        <Dialog :visible="isOpen" class="p-fluid" :style="{ width: '80%', height: 'auto' }" :draggable="false" :closable="false" :modal="true">
            <template #header>
                <h3><i v-tooltip="'Liberar hierarquias da unidade para levantamento'" class="pi pi-info-circle"></i> {{ title }}</h3>
            </template>

            <Panel style="height: 30vw">
                <template #header>
                    <div class="grid grid-nogutter w-full align-items-center">
                        <div v-if="!isEmpty" class="grid grid-nogutter w-full align-items-center">
                            <div id="responsavel" class="field col-4 p-1">
                                <h5 class="p-1" for="responsavel">Responsável</h5>
                                <DropdownProfissionalSesmt v-model="responsavel" :filtrosExtras="null" />
                            </div>
                        </div>

                        <div v-if="!isEmpty" class="grid grid-nogutter w-full">
                            <h5 class="field col-12 ml-1 p-1">Filtros</h5>
                            <div class="field col-4 p-1">
                                <label for="">Cliente</label>
                                <DropdownCliente v-model="filtros.cliente" />
                            </div>

                            <div class="field col-4 p-1">
                                <label for="">Unidade</label>
                                <InfinityDropdownUnidadesByCliente v-model="filtros.unidade" :filtrosExtras="{ customerId: filtros.cliente?.id }" />
                            </div>

                            <div class="field col-4 btn-carregar p-1">
                                <Button icon="pi pi-search" label="Carregar" @click="carregarHierarquias" :disabled="isEmpty" />
                            </div>
                        </div>
                    </div>
                </template>

                <div class="flex flex-column justify-content-center" v-if="isEmpty">
                    <div class="flex align-items-center justify-content-center">
                        <i style="font-size: 5rem" class="pi pi-search"></i>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <h4>Selecione o que deseja fazer: Liberar, Alterar liberação ou Cancelar liberação</h4>
                    </div>
                </div>

                <div v-else>
                    <SimpleGrid ref="liberacaoGrid" :service="service">
                        <template #columns>
                            <Column field="cliente.name" header="Cliente" />
                            <Column field="unidade.name" header="Unidade" />
                            <Column field="setor.name" header="Setor" />
                            <Column field="cargo.name" header="Cargo" />
                            <Column field="atividadeEspecifica" header="Atividade Especifica" />
                            <Column field="statusInventarioRisco" header="Status" />
                        </template>
                    </SimpleGrid>
                </div>
            </Panel>
            <template #footer>
                <Button icon="pi pi-arrow-left" label="Voltar" @click="close" class="p-button bg-primary-reverse" />
                <Button :loading="loadingButtonSalvar" icon="pi pi-check" label="Salvar" :disabled="isEmpty" @click="salvar" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import BaseService from '../../../../../services/BaseService';
import { showError, showWarning, showSuccess } from '../../../../../utils/Toast';
import DropdownCliente from '../../../../clientes/components/DropdownCliente.vue';
import DropdownProfissionalSesmt from '../../../../profissionais-sesmt/components/DropdownProfissionalSesmt.vue';
import InfinityDropdownUnidadesByCliente from '../../../../unidades/components/InfinityDropdownUnidadesByCliente.vue';
import SimpleGrid from '../../SimpleGrid.vue';

export default {
    emits: ['onAfterSave'],
    props: {
        title: String
    },
    components: {
        SimpleGrid,
        DropdownCliente,
        InfinityDropdownUnidadesByCliente,
        DropdownProfissionalSesmt
    },
    data() {
        return {
            isOpen: false,
            isEmpty: false,
            loadingButtonSalvar: false,
            service: null,
            filtrosExtras: {
                where: []
            },
            opcao: {},
            responsavel: null,
            filteredCliente: null,
            responsavelLevantamentoIds: [],
            filtros: {
                cliente: null,
                unidade: null
            },
        };
    },
    created() {
        this.service = new BaseService('liberacao-hierarquia-checklist');
    },
    watch: {
        'filtros.cliente'() {
            this.filtros.unidade = null;
        }
    },
    methods: {
        validar() {
            if (!this.responsavel) {
                const error = {
                    message: 'Nenhum responsável selecionado!'
                };
                showError(this.$toast, error);
                return false;
            }

            const hierarquiaIds = this.getHierarquiaIds();
            if (!hierarquiaIds.length) {
                const error = {
                    message: 'Nenhuma hierarquia selecionada!'
                };
                showError(this.$toast, error);
                return false;
            }
            return true;
        },
        getHierarquiaIds() {
            const records = this.$refs.liberacaoGrid.records;
            return records.map((el) => el.hierarquiaId);
        },
        async salvar() {
            try {
                if (!this.validar()) return;
                this.loadingButtonSalvar = true;
                const hierarquiaIds = this.getHierarquiaIds();
                const responsavelLevantamentoIds = [this.responsavel?.id];
                await this.service.save({ hierarquiaIds, responsavelLevantamentoIds });
                showSuccess(this.$toast, 'Liberação realizada com sucesso!');
                this.close();
                this.loadingButtonSalvar = false;
            } catch (error) {
                this.loadingButtonSalvar = false;
                showError(this.$toast, error);
            }
        },
        montarFiltros() {
            this.limparFiltros();
            this.montarFiltroCliente();
            this.montarFiltroUnidade();
        },
        limparFiltros() {
            this.filtrosExtras = {
                where: []
            };
        },
        montarFiltroTipoHierarquia() {
            this.liberarHierarquias();
        },
        montarFiltroCliente() {
            if (this.filtros.cliente?.id) {
                this.filtrosExtras.where.push({
                    prop: 'cliente.id',
                    operator: 'equal',
                    values: [this.filtros.cliente.id]
                });
            }
        },
        montarFiltroUnidade() {
            if (this.filtros.unidade?.id) {
                this.filtrosExtras.where.push({
                    prop: 'customerBranch.id',
                    operator: 'equal',
                    values: [this.filtros.unidade.id]
                });
            }
        },
        async carregarHierarquias() {
            try {
                if (this.containsInvalidFilters()) {
                    showWarning(this.$toast, 'Preencha os campos CLIENTE e UNIDADE para carregar as hierarquas.');
                    return;
                }
                this.montarFiltros();
                await this.$refs.liberacaoGrid.load(this.filtrosExtras);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        containsInvalidFilters() {
            if (!this.filtros?.cliente?.id || !this.filtros?.unidade?.id) {
                return true;
            }

            return false;
        },
        alterarHierarquias() {
            // TODO
            // Listar hierarquias para alteração
        },
        cancelarHierarquias() {
            // TODO
            // Listar hierarquias para cancelamento
        },
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.resetarCampos();
        },
        resetarCampos() {
            this.responsavel = null;
            this.filtros.cliente = null;
            this.filtros.unidade = null;
            this.opcao = null;
        }
    }
};
</script>

<style>
.btn-carregar {
    margin-top: 20px;
}
</style>
