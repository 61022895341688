<template>
    <PaginatedGrid ref="grid" :service="service" :filtrosExtras="filtrosExtras" :mapFilters="mapFilters">
        <template #columns>
            <Column field="perfilExposicao.nome" header="Perfil / Agrupamento">
                <template #body="slotProps">
                    <div class="truncate-text">
                        {{
                            slotProps?.data?.inventarioRisco?.perfilExposicaoId
                                ? slotProps?.data?.inventarioRisco?.perfilExposicao?.nome
                                : slotProps?.data?.inventarioRisco?.identificacaoAgrupamento
                        }}
                    </div>
                </template>
            </Column>
            <Column field="hierarquia.cliente.grupo.descricao" header="Grupo" />
            <Column field="hierarquia.cliente.name" header="Cliente" />
            <Column field="hierarquia.customerBranch.name" header="Unidade" />
            <Column headerStyle="width:40px;">
                <template #body="{ data }">
                    <div v-tooltip.bottom="'Levantamento em andamento'">
                        <iphoneSmartphone
                            v-if="data?.origemAplicacao === enumOrigemAplicacaoInventarioHierarquia.CHECKLIST"
                            style="
                                 {
                                    width: 25px;
                                    height: 22px;
                                }
                            "
                            :status="data?.aplicacaoLevantamento?.status"
                        />
                    </div>
                    <div v-tooltip.bottom="'Levantamento concluído'">
                        <iphoneSmartphone
                            v-if="data?.origemAplicacao === enumOrigemAplicacaoInventarioHierarquia.CHECKLIST_INVENTARIO"
                            style="
                                 {
                                    width: 25px;
                                    height: 22px;
                                }
                            "
                            :status="data?.aplicacaoLevantamento?.status"
                        />
                    </div>
                </template>
            </Column>
            <Column field="hierarquia.customerDepartment.name" header="Setor" />
            <Column field="hierarquia.customerPosition.name" header="Cargo" />
            <Column header="Status">
                <template #body="{ data }">
                    <StatusAplicacao :status="{ pgr: data?.statusAplicacaoPgr, pcmso: data?.statusAplicacaoPcmso }" />
                </template>
            </Column>
        </template>
        <template v-slot:expansion="slotProps">
            <h5 class="ml-5">{{ `Funcionários: ${totalFuncionarios}` }}</h5>
            <GridFuncionarios
                class="ml-5"
                :service="serviceFuncionario"
                :filtrosExtras="{
                    idHierarquia: slotProps.data.hierarquiaId,
                    situacoes: [
                        enumSituacaoFuncionario.PENDENTE,
                        enumSituacaoFuncionario.ATIVO,
                        enumSituacaoFuncionario.FERIAS,
                        enumSituacaoFuncionario.AFASTADO
                    ]
                }"
                @onChangeTotalItems="onChangeTotalItemsFuncionario"
            >
                <template #columns>
                    <Column field="name" header="Nome" />
                    <Column field="situation" header="Situação" />
                </template>
            </GridFuncionarios>
        </template>
    </PaginatedGrid>
</template>

<script>
import BaseService from '../../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import PaginatedGrid from './PaginatedGrid.vue';
import StatusAplicacaoInventario from '../../../enums/StatusAplicacaoInventario';
import GridFuncionarios from './GridFuncionarios.vue';
import iphoneSmartphone from '../../../../public/layout/images/dashboard/iphoneSmartphone.vue';
import StatusAplicacao from './StatusAplicacao.vue';
import SituationEnum from '../../../enums/SituationEnum';

export default {
    components: {
        PaginatedGrid,
        GridFuncionarios,
        StatusAplicacao,
        iphoneSmartphone
    },
    props: {
        filtrosExtras: {
            type: Object
        }
    },
    data() {
        return {
            service: null,
            serviceFuncionario: null,
            enumSituacaoFuncionario: SituationEnum,
            totalFuncionarios: 0,
            enumStatusAplicacaoInventario: StatusAplicacaoInventario,
            mapFilters: [
                {
                    filter: 'cliente',
                    value: 'cliente.name'
                },
                {
                    filter: 'unidade',
                    value: 'customerBranch.name'
                },
                {
                    filter: 'setor',
                    value: 'customerDepartment.name'
                },
                {
                    filter: 'cargo',
                    value: 'customerPosition.name'
                },
                {
                    filter: 'funcionario',
                    value: 'customerEmployee.name'
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/inventario-risco/aguardando-vigencia');
        this.serviceFuncionario = new BaseService('/customer/employees');
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    methods: {
        load() {
            this.$refs?.grid?.load();
        },
        onChangeTotalItemsFuncionario(totalFuncionarios) {
            this.totalFuncionarios = totalFuncionarios;
        }
    }
};
</script>

<style>
.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* Defina a largura máxima da célula conforme necessário */
}

.truncate-text:hover {
    white-space: normal;
    overflow: visible;
    max-width: none;
}
</style>
