<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
        >
            <template #empty> Nenhum registro encontrado. </template>
            <slot name="columns"></slot>
        </DataTable>

        <Paginator
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>
    </div>
</template>

<script>
export default {
    emits: ['onChangeTotalItems'],
    props: {
        service: {
            type: Object,
            required: true
        },
        firstPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        filtrosExtras: {
            type: Object
        }
    },
    watch: {
        filtrosExtras() {
            this.load();
        }
    },
    data() {
        return {
            loading:false,
            records: [],
            total: 0,
            page: 1,
            currentPerPage: null,
        }
    },
    mounted() {
        this.$service = this.service;
        this.page = this.firstPage;
        this.currentPerPage = this.perPage;
        this.load();
    },
    methods: {
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll({
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtras
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.$emit('onChangeTotalItems', this.total);
                this.loading = false;
            } catch (error) {
                this.loading = false;
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao listar! ' + message,
                    life: 3000
                });
            }
        }
    }
};
</script>