<template>
    <Dialog :style="{ width: '750px' }" header="Procedimentos para o cliente" :modal="true">
        <template #header>
            <div class="mt-1">
                <h2>Parâmetros para geração do relatório quantificação complementar</h2>
            </div>
        </template>

        <div class="field">
            <label for="cliente">Cliente</label>
            <DropdownCliente id="cliente" v-model="form.cliente" :showClear="true" class="w-full" />
        </div>
        <div class="field">
            <label for="Unidade">Unidade</label>
            <InfinityDropdownUnidadesByCliente
                id="cliente"
                v-model="form.unidade"
                :disabled="enabledUnidade"
                class="w-full"
                :filtrosExtras="{ customerId: form?.cliente?.id || 0 }"
            />
        </div>

        <template #footer>
            <div class="flex flex-row justify-content-end">
                <div>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
                    <Button class="m-0" label="Exportar dados" @click="exportarDados" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import axios from 'axios';
import { getClientBase } from '../../../services/http';
import DropdownCliente from '../../clientes/components/DropdownCliente.vue';
import { getTenant, getToken } from '../../../common/storage';
import InfinityDropdownUnidadesByCliente from '../../unidades/components/InfinityDropdownUnidadesByCliente.vue';

export default {
    components: {
        DropdownCliente,
        InfinityDropdownUnidadesByCliente
    },
    data() {
        return {
            form: {},
            enabledUnidade: true
        };
    },
    created() {
        this.$service = getClientBase();
    },
    watch: {
        'form.cliente'(value) {
            if (value) {
                this.enabledUnidade = false;
                return;
            }
            this.limparFiltros();
            this.enabledUnidade = true;
        }
    },
    methods: {
        limparFiltros() {
            this.form.unidade = null;
            this.form.versao = null;
        },
        async exportarDados() {
            const dto = {
                customer: {
                    id: this.form.cliente.id,
                    name: this.form.cliente.name
                },
                customerBranch: {
                    id: this.form.unidade.id,
                    name: this.form.unidade.name
                },
                enviarPorEmail: false
            };

            const res = await axios.post(process.env.VUE_APP_API_BASE_URL + `/inventario-risco/exportar/ghe`, dto, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    'x-api-key': getTenant()
                }
            });

            const file = window.URL.createObjectURL(new Blob([res.data]));
            const docUrl = document.createElement('a');
            docUrl.href = file;
            docUrl.setAttribute('download', `${dto.customerBranch.id}-relatório_ghe.xlsx`);
            document.body.appendChild(docUrl);
            docUrl.click();
        },
        validar() {
            if (!this.form?.cliente) return true;
            if (!this.form?.unidade) return true;
            return false;
        }
    }
};
</script>

<style></style>
