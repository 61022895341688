<template>
    <Dialog v-if="isOpen" v-model:visible="isOpen" :style="{ width: '550px' }" :modal="true" :draggable="false">
        <template #header>
            <div>
                <h2>Relatório enviado com sucesso</h2>
            </div>
        </template>

        <div class="flex flex-row justify-content-center align-items-center">
            <img src="../../../../public/layout/images/atualizacao-prestador/finalAtualizaPrestador.svg" alt="">
        </div>

        <template #footer>
            <div class="flex flex-row justify-content-end">
                <div>
                    <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="close" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
export default {
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        }
    }
}
</script>

<style></style>