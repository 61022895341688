<template>
    <div>
        <PanelInventarioRisco subtitle="Inventário de Risco" tooltip="Gerenciamento de Riscos, Procedimentos, Parecer e Medidas de Controle">
            <template #filtros>
                <div class="flex flex-column mb-1 md:flex-row md:justify-content-between md:align-items-center">
                    <FiltroInventarioRisco class="w-6" @onChangeFiltro="onChangeFiltro" />
                    <div class="mt-2 ml-auto md:mt-0">
                        <Button v-if="$checkPermission('smt_ir:aplicar_massa')" :loading="loadingAplicarEmMassa" label="Aplicação em massa" class="p-button" @click="aplicarEmMassa" />
                        <Button v-if="$checkPermission('smt_ir:libera_checklist')" label="Liberar unidade para checklist digital" class="p-button bg-primary-reverse ml-2 mr-2" @click="checklistDigital" />
                        <Button  v-if="$checkPermission('smt_ir:impr_relatorio')" label="Imprimir relatórios" class="p-button" @click="toggle" />
                    </div>
                </div>
            </template>
            <template #content>
                <TabView ref='tabview' :activeIndex="activeTab" @tab-click="onTabClick">
                    <TabPanel ref='tabpanel' header="Sem aplicação">
                        <GridSemAplicacao :filtrosExtras="filtrosExtras" ref="gridsemaplicacao" />
                    </TabPanel>
                    <TabPanel header="Em andamento">
                        <GridEmAndamento :filtrosExtras="filtrosExtras" ref="gridemandamento" />
                    </TabPanel>
                    <TabPanel header="Aguardando vigência">
                        <GridAguardandoVigencia :filtrosExtras="filtrosExtras" ref="gridaguardandovigencia" />
                    </TabPanel>
                    <TabPanel header="Concluído">
                        <GridConcluidos :filtrosExtras="filtrosExtras" ref="gridconcluidos" />
                    </TabPanel>
                </TabView>
            </template>
        </PanelInventarioRisco>

        <LiberacaoChecklistDialog
            ref="liberacaoChecklistDialog"
            :title="'CHECKLIST DIGITAL'"
        />

        <DialogGerarRelatorioInventario
            ref="dialogRelatorioInventario"
            @onSuccess="onSuccess"
        />
        <DialogExecucaoRelatorioInventario
            ref="dialogExecucaoRelatorio"
        />

        <DialogRelatorioHierarquiaGHE
            v-if="showDialogGHE"
            v-model:visible="showDialogGHE"
            @onClose="closeRelatorioHierarquiaGHE"
        />

        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
    </div>
</template>

<script>
import PanelInventarioRisco from './components/PanelInventarioRisco.vue';
import FiltroInventarioRisco from './components/FiltroInventarioRisco.vue';
import GridSemAplicacao from './components/GridSemAplicacao.vue';
import GridEmAndamento from './components/GridEmAndamento.vue';
import GridAguardandoVigencia from './components/GridAguardandoVigencia.vue';
import GridConcluidos from './components/GridConcluidos.vue';
import LiberacaoChecklistDialog from './components/checklist/checklist-digital/LiberacaoChecklistDialog.vue';
import { showError } from '../../utils/Toast';
import DialogGerarRelatorioInventario from './components/DialogGerarRelatorioInventario.vue';
import DialogExecucaoRelatorioInventario from './components/DialogExecucaoRelatorioInventario.vue';
import DialogRelatorioHierarquiaGHE from './components/DialogRelatorioHierarquiaGHE.vue';

export default {
    components: {
        PanelInventarioRisco,
        FiltroInventarioRisco,
        GridSemAplicacao,
        GridEmAndamento,
        GridAguardandoVigencia,
        GridConcluidos,
        LiberacaoChecklistDialog,
        DialogGerarRelatorioInventario,
        DialogExecucaoRelatorioInventario,
        DialogRelatorioHierarquiaGHE
    },
    data() {
        return {
            loadingAplicarEmMassa: false,
            showDialogRelatorio: false,
            showDialogExecucaoRelatorio: false,
            showDialogGHE: false,
            activeTab: 0,
            filtrosExtras: {},
            items: [
                {
                    label: 'Relatórios',
                    items: [
                        {
                            label: 'Levantamento - visita técnica',
                            command: () => {
                                this.showImprimirLevantamento();
                            }
                        }
                    ]
                },
                {
                    label: 'Exportação',
                    items: [
                        {
                            label: 'Hierarquias - quantificação complementar',
                            command: () => {
                                this.showDialogGHE = true;
                            }   
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        checklistDigital() {
            this.$refs.liberacaoChecklistDialog.open();
        },
        onTabClick(event) {
            this.activeTab = event.index;

            if (this.activeTab === 0) this.$refs?.gridsemaplicacao?.load();
            if (this.activeTab === 1) this.$refs?.gridemandamento?.load();
            if (this.activeTab === 2) this.$refs?.gridaguardandovigencia?.load();
            if (this.activeTab === 3) this.$refs?.gridconcluidos?.load();
        },
        onChangeFiltro(filtrosExtras) {
            this.filtrosExtras = filtrosExtras;
        },
        async aplicarEmMassa() {
            try {
                this.loadingAplicarEmMassa = true;
                await this.$refs.gridsemaplicacao.aplicarEmMassa();
                this.loadingAplicarEmMassa = false;
            } catch (error) {
                this.loadingAplicarEmMassa = false;
                showError(this.$toast, error);
            }
        },
        onSuccess() {
            this.$refs.dialogExecucaoRelatorio.open();
        },
        closeImprimirLevantamentoDialog() {
            this.showDialogRelatorio = false;
        },
        closeDialogExecucaoRelatorio() {
            this.showDialogExecucaoRelatorio = false;
        },
        showImprimirLevantamento() {
           this.$refs.dialogRelatorioInventario.open();
        },
        closeRelatorioHierarquiaGHE() {
            this.showDialogGHE = false;
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        }
    }
};
</script>