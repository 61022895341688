<template>
    <div>
        <AppLoadingWrapper v-if="loading" />
        <DataTable
            v-if="!loading"
            dataKey="id"
            :value="records"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            v-model:expandedRows="expandedRows"
            v-model:selection="selectedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
        >
            <template #empty>
                <div class="flex flex-column justify-content-center align-items-center p-2">
                    <i v-if="showDefaultMesage" class="pi pi-search mb-5" style="font-size: 10rem; color: var(--gray-500);"></i>
                    <span v-if="showDefaultMesage" style="font-size: 2rem; color: var(--gray-500);">Faça uma pesquisa ou utilize os filtros para mostrar resultados.</span>
                    <span v-else style="font-size: 2rem; color: var(--gray-500);">Nenhum dado encontrado.</span>
                </div>
            </template>
            <Column v-if="selection" selectionMode="multiple"></Column>
            <Column v-if="expander" :expander="true" headerStyle="width: 3rem" />

            <slot name="columns"></slot>

            <template v-if="expander" #expansion="slotProps">
                <slot name="expansion" v-bind:data="slotProps?.data"></slot>
            </template>
        </DataTable>

        <Paginator
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {currentPage} de {totalPages} registros"
        >
        </Paginator>
    </div>
</template>

<script>
import { montarFiltrosExtras } from '../../../utils/FiltrosExtras';
export default {
    emits: ['onRowExpand'],
    props: {
        service: {
            type: Object,
            required: true
        },
        firstPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        filtrosExtras: {
            type: Object
        },
        selection: {
            type: Boolean,
            default: true
        },
        expander: {
            type: Boolean,
            default: true
        },
        mapFilters: {
            type: Array
        },
        showDefaultMesage: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        'filtrosExtras'(newValue) {
            this.filtrosExtrasLocal = montarFiltrosExtras(newValue, this.mapFilters);
            this.load();
        }
    },
    data() {
        return {
            loading:false,
            filtrosExtrasLocal: {},
            records: [],
            selectedRows: [],
            expandedRows: [],
            total: 0,
            page: 1,
            currentPerPage: null,
        }
    },
    mounted() {
        this.$service = this.service;
        this.page = this.firstPage;
        this.currentPerPage = this.perPage;
        this.filtrosExtrasLocal = montarFiltrosExtras(this.filtrosExtras, this.mapFilters);
    },
    methods: {
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.findAll({
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtrasLocal
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao listar! ' + message,
                    life: 3000
                });
            }
        },
        onRowExpand(event) {
            this.expandedRows = [event.data];
            this.$emit('onRowExpand', event);
        },
        onRowCollapse() {
            this.expandedRows = [];
        }
    }
};
</script>