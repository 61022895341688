<template>
    <div>
        <i
            v-if="status?.statusInventario === 'RASCUNHO'"
            class="pi pi-user-edit mr-2"
            :style="getStatusInventarioColor(status?.statusInventario)"
            v-tooltip.bottom="getStatusInventarioTooltip(status?.statusInventario)"
        ></i>
        <i
            v-if="status?.levantamento"
            class="mr-2"
            :class="enumEtapaInventarioRiscoIcon.get(enumEtapaInventarioRisco.LEVANTAMENTO_PERIGO_RISCO)"
            :style="getStatusColor(status?.levantamento)"
            v-tooltip.bottom="'Levantamento ' + getStatusTooltip(status?.levantamento)"
        ></i>
        <i
            v-if="status?.aepAet"
            class="mr-2"
            :class="enumEtapaInventarioRiscoIcon.get(enumEtapaInventarioRisco.AEP_AET)"
            :style="getStatusColor(status?.aepAet)"
            v-tooltip.bottom="'AEP/AET ' + getStatusTooltip(status?.aepAet)"
        ></i>
        <i
            v-if="status?.pgr"
            class="mr-2"
            :class="enumEtapaInventarioRiscoIcon.get(enumEtapaInventarioRisco.PGR)"
            :style="getStatusColor(status?.pgr)"
            v-tooltip.bottom="'PGR ' + getStatusTooltip(status?.pgr)"
        ></i>
        <i
            v-if="status?.laudo"
            class="mr-2"
            :class="enumEtapaInventarioRiscoIcon.get(enumEtapaInventarioRisco.LTCAT_LTIP)"
            :style="getStatusColor(status?.laudo)"
            v-tooltip.bottom="'Laudo ' + getStatusTooltip(status?.laudo)"
        ></i>
        <i
            v-if="status?.pcmso"
            class="mr-2"
            :class="enumEtapaInventarioRiscoIcon.get(enumEtapaInventarioRisco.PCMSO)"
            :style="getStatusColor(status?.pcmso)"
            v-tooltip.bottom="'PCMSO ' + getStatusTooltip(status?.pcmso)"
        ></i>
    </div>
</template>

<script>
import EtapasInventarioRiscoEnum, { EtapaInventarioRiscoIconEnum } from '../../../enums/EtapasInventarioRiscoEnum';

export default {
    props: {
        status: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            enumEtapaInventarioRisco: EtapasInventarioRiscoEnum,
            enumEtapaInventarioRiscoIcon: EtapaInventarioRiscoIconEnum
        }
    },
    methods: {
        getStatusInventarioColor(status) {
            const Status = {
                'RASCUNHO': 'color: var(--orange-500)'
            }
            
            return Status[status];
        },
        getStatusInventarioTooltip(status) {
            const Status = {
                'RASCUNHO': 'Em edição por outro usuário'
            }

            return Status[status];
        },
        getStatusColor(status) {
            const Status = {
                'NAO_CONTRATADO': 'color: var(--gray-200)',
                'NAO_INICIADO': 'color: var(--gray-500)',
                'EM_ANDAMENTO': 'color: var(--yellow-500)',
                'CONCLUIDO': 'color: var(--green-500)',
            }

            return Status[status];
        },
        getStatusTooltip(status) {
            const Status = {
                'NAO_CONTRATADO': 'não contratado',
                'NAO_INICIADO': 'não iniciado',
                'EM_ANDAMENTO': 'em andamento',
                'CONCLUIDO': 'concluído',
            }

            return Status[status];
        },
    }
};
</script>