<template>
    <div>
        <PaginatedGrid
            ref="grid"
            :filtrosExtras="filtrosExtras"
            :mapFilters="mapFilters"
            :service="service"
            :selection="$checkPermission('smt_ir:editar') || $checkPermission('smt_ir:excluir') || $checkPermission('smt_ir:aplicar') "
        >
            <template #columns>
                <Column header="Produtos/Serviços contratados">
                    <template #body="{ data }">
                        <ProgramasServicosContratados :records="data?.cliente?.produtosServicosCliente" />
                    </template>
                </Column>
                <Column field="cliente.grupo.descricao" header="Grupo" />
                <Column field="cliente.name" header="Cliente" />
                <Column field="customerBranch.name" header="Unidade" />
                <Column>
                    <template #body="{ data }">
                        <StatusAplicacao :status="{ statusInventario: data?.statusInventarioRisco }" />
                    </template>
                </Column>
                <Column field="customerDepartment.name" header="Setor" />
                <Column field="customerPosition.name" header="Cargo" />
                <Column>
                    <template #body="slotProps">
                        <Button
                            v-if="$checkPermission('smt_ir:aplicar')"
                            :loading="!!loadItemsAplicarIndividual[loadItemsAplicarIndividual.indexOf(slotProps.data.id)]"
                            :disabled="slotProps?.data?.statusInventarioRisco === 'RASCUNHO'" 
                            label="Aplicar"
                            class="p-button-sm p-button-outlined"
                            @click="aplicarIndividual(slotProps)"
                        />
                        <Button
                            v-if="$checkPermission('smt_ir:editar')"
                            :disabled="!(slotProps?.data?.statusInventarioRisco === 'RASCUNHO')" 
                            icon="pi pi-pencil"
                            class="p-button-sm p-button-outlined"
                            @click="editarRascunho(slotProps)"
                        />
                        <Button
                            v-if="$checkPermission('smt_ir:excluir')"
                            :disabled="!(slotProps?.data?.statusInventarioRisco === 'RASCUNHO')" 
                            icon="pi pi-trash"
                            class="p-button-sm p-button-outlined"
                            @click="openDialog(slotProps)"
                        />
                        
                    </template>
                </Column>
            </template>
            <template v-slot:expansion="slotProps">
                <h5 class="ml-5">{{`Funcionários: ${totalFuncionarios}`}}</h5>
                <GridFuncionarios
                    class="ml-5"
                    :service="serviceFuncionario"
                    :filtrosExtras="{
                        idHierarquia: slotProps.data.id,
                        situacoes: [
                            enumSituacaoFuncionario.PENDENTE,
                            enumSituacaoFuncionario.ATIVO,
                            enumSituacaoFuncionario.FERIAS,
                            enumSituacaoFuncionario.AFASTADO
                        ]
                    }"
                    @onChangeTotalItems="onChangeTotalItemsFuncionario"
                >
                    <template #columns>
                        <Column field="name" header="Nome" />
                        <Column field="situation" header="Situação" />
                    </template>
                </GridFuncionarios>
            </template>
        </PaginatedGrid>

        <DialogRemoverRascunho
            ref="dialogRemoverRascunho"
            @onAfterConfirmRemoverRascunho="onAfterConfirmRemoverRascunho"
            :rowData="rowData"
        />
    </div>
</template>

<script>
import BaseService from '../../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store'
import PaginatedGrid from './PaginatedGrid.vue';
import GridFuncionarios from './GridFuncionarios.vue';
import StatusAplicacao from './StatusAplicacao.vue';
import DialogRemoverRascunho from '../components/DialogRemoverRascunho.vue';
import InventarioRiscoService from '../services/InventarioRiscoService';
import { showError, showSuccess } from '../../../utils/Toast';
import ProgramasServicosContratados from './ProgramasServicosContratados.vue';
import SituationEnum from '../../../enums/SituationEnum';

export default {
    emits: ['onSaveAplicacaoRisco', 'onConfirmAplicacaoRisco'],
    components: {
        PaginatedGrid,
        GridFuncionarios,
        StatusAplicacao,
        DialogRemoverRascunho,
        ProgramasServicosContratados
    },
    props: {
        filtrosExtras: {
            type: Object
        }
    },
    data() {
        return {
            service: null,
            serviceFuncionario: null,
            serviceInventarioRisco: null,
            totalFuncionarios: 0,
            enumSituacaoFuncionario: SituationEnum,
            rowData: {},
            loadItemsAplicarIndividual: [],
            mapFilters: [
                {
                    filter: 'cliente',
                    value: 'cliente.name'
                },
                {
                    filter: 'unidade',
                    value: 'customerBranch.name'
                },
                {
                    filter: 'setor',
                    value: 'customerDepartment.name'
                },
                {
                    filter: 'cargo',
                    value: 'customerPosition.name'
                },
                {
                    filter: 'funcionario',
                    value: 'customerEmployee.name'
                }
            ]
        }
    },
    created() {
        this.service = new BaseService('/inventario-risco/sem-aplicacao');
        this.serviceFuncionario = new BaseService('/customer/employees');
        this.serviceInventarioRisco = new InventarioRiscoService();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    methods: {
        load() {
            this.$refs?.grid?.load();
        },
        onChangeTotalItemsFuncionario(totalFuncionarios) {
            this.totalFuncionarios = totalFuncionarios;
        },
        editarRascunho(slotProps) {
            const { inventarioRiscoId } = slotProps.data;
            this.$router.push(`/security/inventario-risco2/aplicacao/${inventarioRiscoId}/`);
        },
        openDialog(slotProps) {
            this.$refs.dialogRemoverRascunho.open(slotProps.data);
        },
        onAfterConfirmRemoverRascunho() {
            this.rowData = {};
            this.load();
        },
        async aplicarEmMassa() {
            const hierarquiaIds = this.$refs.grid.selectedRows.map((item) => item.id);
            await this.aplicar(hierarquiaIds);
            showSuccess(this.$toast, 'Rascunho da aplicação iniciado com sucesso!');
        },
        async aplicarIndividual(slotProps) {
            try {
                this.loadItemsAplicarIndividual.push(slotProps.data.id);
                const hierarquiaIds = [ slotProps.data.id ];
                await this.aplicar(hierarquiaIds);
                this.loadItemsAplicarIndividual = [];
            } catch (error) {
                this.loadItemsAplicarIndividual = [];
                showError(this.$toast, error);
            }
        },
        async aplicar(hierarquiaIds) {
            const { data } = await this.serviceInventarioRisco.save({ hierarquiaIds });
            this.$router.push(`/security/inventario-risco2/aplicacao/${data.id}/`);
        },
        onSaveAplicacaoRisco() {
            this.load();
        },
        onConfirmAplicacaoRisco() {
            this.load();
        }
    }
};
</script>