<template>
    <div>
        <Dialog :visible="isOpen" class="p-fluid" :style="{ width: 'auto' }" header="Remover hierarquia" :modal="true">
            <GridAplicacaoInventarioRisco ref="gridAplicacaoInventarioRisco" @onAfterConfirmDialog="onAfterConfirmDialog"/>
            <h5>Deseja remover o perfil de exposição inteiro?</h5>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="onCloseDialog" />
                <Button label="Perfil inteiro" icon="pi pi-check" class="p-button-danger" autofocus @click="onConfirmDialog" />
            </template>
        </Dialog>
        <Dialog :visible="isOpenConfirmDialog" :closable="false" :modal="true">
                <div class="flex flex-column justify-content-center align-items-center">
                    <div class="flex-1 pb-4">
                        <i class="pi pi-exclamation-triangle text-7xl text-400"></i>
                    </div>
                    <div class="flex">
                        <p class="font-medium">Esta ação irá remover todas as hierarquias do perfil de exposição.</p>
                    </div>
                    <div class="flex">
                        <p class="font-medium">Tem certeza?</p>
                    </div>
                    
                    <div class="flex">
                        <p class="font-bold">Você perderá todas as respostas incluídas até aqui!</p>
                    </div>
                    <div class="flex">
                        <p class="font-bold">Esta ação não poderá ser desfeita!</p>
                    </div>
                </div>
            <template #footer>
                <Button label="Não" icon="pi pi-times" class="p-button-text" autofocus @click="onCloseDialogConfirm" />
                <Button 
                    :loading="loadingRemoverTodos" 
                    label="Sim" 
                    icon="pi pi-check" 
                    class="p-button-danger"
                    @click="onConfirmDialogConfirm" 
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import { getClientBase } from '../../../services/http';
import InventarioHierarquiaService from '../services/InventarioHierarquiaService';
import GridAplicacaoInventarioRisco from './GridAplicacaoInventarioRisco.vue';
export default {
    emits:['onCloseDialog', 'onloadEmAndamento'],
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    components: {
        GridAplicacaoInventarioRisco
    },
    data() {
        return {
            service: null,
            form:{
                inventarioRiscoId: null
            },
            isOpenConfirmDialog: false,
            loadingRemoverTodos: false
        };
    },
    created() {
        this.service = new InventarioHierarquiaService();
    },
    methods: {
        onAfterConfirmDialog(){
            this.$refs.gridAplicacaoInventarioRisco.load(this.form.inventarioRiscoId);
            this.$emit('onloadEmAndamento');
        },
        onConfirmDialog() {
            this.isOpenConfirmDialog = true;
        },
        onCloseDialog() {
            this.$emit('onCloseDialog');
        },
        async onConfirmDialogConfirm() {
            try {
                this.loadingRemoverTodos = true;
                await getClientBase().delete('inventario-hierarquia/remove-todos-inventario-hierarquia/' + this.form.inventarioRiscoId);
                this.loadingRemoverTodos = false;
                this.onCloseDialogConfirm();
                await this.$emit('onloadEmAndamento');
                this.$emit('onCloseDialog');
            } catch (error) {
                this.loadingRemoverTodos = false;
            }
        },  
        onCloseDialogConfirm() {
            this.isOpenConfirmDialog = false;
        },
        async load(id) {
            this.form.inventarioRiscoId = id;
            setTimeout(async () => await this.$refs.gridAplicacaoInventarioRisco.load(id), 0);
        }
    }
};
</script>

