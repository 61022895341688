<template>
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        :style="{ color: getStatusColor(status) }"
        fill="currentColor"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
            <path
                d="M1397 5109 c-106 -25 -201 -104 -251 -209 l-31 -65 0 -2275 0 -2275
                31 -65 c39 -83 101 -145 184 -184 l65 -31 1165 0 1165 0 65 31 c83 39 145 101
                184 184 l31 65 0 2275 0 2275 -31 65 c-39 83 -101 145 -184 184 l-65 31 -1145
                2 c-646 1 -1161 -3 -1183 -8z m1723 -234 c7 -9 15 -37 17 -63 4 -38 1 -53 -17
                -74 l-21 -28 -534 0 c-510 0 -534 1 -556 19 -20 16 -24 29 -24 74 0 94 -47 87
                575 87 480 0 549 -2 560 -15z m610 -2040 l0 -1655 -1170 0 -1170 0 0 1655 0
                1655 1170 0 1170 0 0 -1655z m-1062 -2021 c115 -57 160 -198 101 -315 -85
                -170 -333 -170 -418 0 -80 158 33 341 209 341 40 0 71 -7 108 -26z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        status: String
    },
    methods: {
        getStatusColor(status) {
            const Status = {
                NAO_CONTRATADO: 'var(--gray-200)',
                NAO_INICIADO: 'var(--gray-500)',
                EM_ANDAMENTO: '#ffbf00',
                CONCLUIDO: 'var(--green-500)'
            };

            return Status[status];
        }
    }
};
</script>
